import { Languages, LocalStorageKeys, PageTypeSuffix } from '@/constants'
import { useNotification } from '@/containers'
import { useDebounce } from '@/hooks/useDebounce'
import { type Category, type Product } from '@/types'
import { api } from '@/utils'
import { getTranslate } from '@/utils/api-interceptors'
import { getCategories, getProductWithPaginate } from '@/utils/endpoint'
import { useClickOutsideComponent } from '@/utils/useClickOutsideComponent'
import { useTranslations } from 'next-intl'
import Link from 'next/link'
import { useRouter } from 'next/router'
import React, { useEffect, useState } from 'react'
import { SearchIcon } from '../Icon/HeaderIcon'
import { SearchProductItemCard } from '../SearchProductItemCard'

export const SearchBar = () => {
  const maxItemsReached = 3
  const t = useTranslations('Header')
  const { systemConfig } = useNotification()
  const [searchBarValue, setSearchBarValue] = useState('')
  const { ref, isComponentVisible, setIsComponentVisible } =
    useClickOutsideComponent(false)
  const router = useRouter()
  const debouncedSearchBarValue = useDebounce(searchBarValue, 500)

  const [displayResult, setDisplayResult] = useState<{
    products: Product[] | null | undefined
    categories: Category[] | null | undefined
  } | null>(null)

  const handleInputKeyUp = async (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === 'Backspace' || event.key === 'Delete') {
      if (searchBarValue === '') {
        localStorage?.removeItem(LocalStorageKeys.ITEM_LISTING_SEARCH)
      }
    }
    if (event.key === 'Enter') {
      const res = await api(`api/keyword-cat?keyword=${searchBarValue}`, {
        method: 'get',
        baseURL: process.env.NEXT_PUBLIC_CATALOG_API_URL,
      })
      if (res && !(res instanceof Error) && res.data) {
        router.push(
          getTranslate(res?.data?.slugs?.value) +
            PageTypeSuffix.CATEGORY +
            `?query=${searchBarValue}`
        )
      } else {
        return router.push(`/search?query=${searchBarValue}`)
      }
    }
  }

  useEffect(() => {
    if (router.query && router.query.query) {
      setSearchBarValue(router.query.query as string)
    }
  }, [router.query])

  useEffect(() => {
    const query = router.query
    if (debouncedSearchBarValue && router.query) {
      Promise.all([
        getProductWithPaginate({
          ...query,
          fullTextSearch: debouncedSearchBarValue,
          limit: maxItemsReached,
          sortBy: 'score',
          sortType: 'desc',
        }).then(res => res?.data),
        getCategories({
          ...query,
          fullTextSearch: debouncedSearchBarValue,
          limit: maxItemsReached,
          sortBy: 'score',
          sortType: 'desc',
        }).then(res => res?.data),
      ]).then(res => {
        const [products, categories] = res
        setDisplayResult({
          products,
          categories,
        })
      })
    } else {
      setDisplayResult(null)
    }
  }, [debouncedSearchBarValue])

  const EmptyMessage = () => (
    <h3 className='EmptyMessage flex h-12 w-full items-center bg-grey-200 pl-2 text-Headline text-dark-400'>
      {t('searching')}
    </h3>
  )

  return (
    <>
      <input
        type='text'
        className={`!h-12 w-full rounded-s-lg border-[1.5px] border-product-salePrice p-3 shadow-none transition hover:cursor-pointer focus:shadow-none focus:outline-none focus:ring-0`}
        placeholder={t('searchPld', { brand: systemConfig?.websiteBrandName })}
        value={searchBarValue}
        onChange={e => {
          setSearchBarValue(e.target.value)
          setIsComponentVisible(true)
        }}
        onKeyUp={e => handleInputKeyUp(e)}
      />
      <span
        className='start-[-1px] flex h-12 w-12 min-w-[48px] cursor-pointer items-center justify-center rounded-e-lg bg-product-salePrice p-0'
        onClick={() => {
          if (searchBarValue !== '')
            return router.push(`/search?query=${searchBarValue}`)
        }}
      >
        <SearchIcon className='w-[20px]' />
      </span>

      {/* RESULTS */}
      {isComponentVisible && searchBarValue.length > 0 && (
        <div
          className='absolute left-0 top-0 z-[999] mt-12 w-[500px] rounded bg-white-400 shadow-grey-400 drop-shadow'
          ref={ref}
        >
          {displayResult ? (
            <>
              <div className='searchProductResult w-full'>
                <h3 className='searchProductResultHeader flex h-12 w-full items-center bg-grey-200 pl-2 text-Headline text-dark-400'>
                  {t('productSuggest')}
                </h3>
                <div className='searchProductResultContent grid'>
                  {displayResult.products?.map(el => (
                    <Link
                      onClick={() => setIsComponentVisible(false)}
                      key={el._id}
                      href={
                        '/' + getTranslate(el.slugs.value) + el.slugs.postfix
                      }
                      className='block'
                    >
                      <SearchProductItemCard
                        id={el._id}
                        price={el.finalPrice
                          .toLocaleString(Languages.VN, {
                            style: 'currency',
                            currency: 'VND',
                          })
                          .replace(/\s₫/g, 'đ')}
                        name={getTranslate(el.name)}
                        imgSrc={el?.thumbnail}
                        imgAlt={getTranslate(el.name)}
                      />
                    </Link>
                  ))}
                </div>
              </div>
            </>
          ) : (
            <>
              <EmptyMessage />
            </>
          )}
        </div>
      )}
    </>
  )
}
