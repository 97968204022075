import FormGroup from '@/components/Form/FormGroup'
import { HeaderLogo } from '@/components/HeaderLogo'
import { AccountTrigger } from '@/components/Icon/AccountTrigger'
import { IconLocation, IconsDownsLocate } from '@/components/Icon/HeaderIcon'
import { SearchBar } from '@/components/SearchBar'
import { TopHeader } from '@/components/TopHeader'
import { useAuth, useStore } from '@/containers'
import { IconCartHome, IconDelivery } from '@/styles/Icon'
import { type UserAddress } from '@/types'
import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import { useTranslations } from 'next-intl'
import dynamic from 'next/dynamic'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useMemo, useState } from 'react'

interface HeaderLogo {
  label: JSX.Element | null
  key: number
}
const AddAddressShipping = dynamic(
  () => import('@/components/Popup/AddAddressShipping'),
  {
    ssr: false,
  }
)
const ListAddressShipping = dynamic(
  () => import('@/components/Popup/ListAddressShipping'),
  {
    ssr: false,
  }
)
type HeaderProps = {
  page?: string
}
const Header = ({ page }: HeaderProps) => {
  const t = useTranslations('Header')
  const {
    user,
    logout,
    cart,
    userAddress,
    isOpenAddAddress,
    isOpenListAddress,
    setIsOpenListAddress,
    setIsOpenAddAddress,
  } = useAuth()
  const { regions, saleRegion, topBanner, setIsOpenPopupLocation } = useStore()
  const [address, setAddress] = useState<UserAddress>()
  const lastName = user?.name.split(' ').slice(-1).join(' ')
  const router = useRouter()
  const renderInput = useMemo(() => {
    const store = regions.find(e => e.code === saleRegion)

    return (
      <div
        onClick={() => setIsOpenPopupLocation(true)}
        className='flex h-12 w-[160px] max-w-[160px] cursor-pointer items-center gap-2 rounded-lg border border-solid border-gray-300 px-1 leading-tight'
      >
        <div className='w-4'>
          <IconLocation />
        </div>

        <div className='flex flex-wrap text-sm text-dark-900'>
          <p className='w-full'>Xem giá, tồn tại:</p>
          <div className='flex items-center gap-1'>
            <span className='line-clamp-1 font-bold'>{store?.name}</span>
            <IconsDownsLocate />
          </div>
        </div>
      </div>
    )
  }, [regions, saleRegion])
  return (
    <header className='relative z-[50] bg-white'>
      {topBanner ? <TopHeader topBanner={topBanner} /> : ''}
      <div className={`container mx-auto py-2`}>
        <div className={`flex items-center`}>
          {page && page == 'homepage' ? (
            <h1 className='max-w-[140px] flex-[140px] justify-center md:max-w-[180px] md:flex-[180px] lg:max-w-[240px] lg:flex-[240px]'>
              <HeaderLogo />
            </h1>
          ) : (
            <div className='max-w-[140px] flex-[140px] justify-center md:max-w-[180px] md:flex-[180px] lg:max-w-[240px] lg:flex-[240px]'>
              <HeaderLogo />
            </div>
          )}
          {renderInput}
          <div className='h-full max-w-[360px] flex-[360px] items-center pl-3 md:max-w-[400px] md:flex-[400px] lg:max-w-[460px] lg:flex-[460px]'>
            <FormGroup className='relative flex flex-row'>
              <SearchBar />
            </FormGroup>
          </div>
          <div className='flex h-full flex-1 items-center justify-end gap-2 leading-tight xl:gap-4 '>
            <div className='h-full items-center leading-[20px] md:py-1 xl:py-2'>
              {user ? (
                <Link
                  href={'/orders'}
                  className='flex h-full items-center gap-2'
                >
                  <IconDelivery />
                  <span className='max-w-[75px]'>{t('searchOrder')}</span>
                </Link>
              ) : (
                <Link
                  href={'/search-order'}
                  className='flex h-full items-center gap-2'
                >
                  <IconDelivery />
                  <span className='max-w-[75px]'>{t('searchOrder')}</span>
                </Link>
              )}
            </div>
            <div className='h-full items-center md:py-1 xl:py-2'>
              <Link
                href='/checkout/cart'
                className='relative flex h-full items-center gap-2'
              >
                <IconCartHome />
                <p className=' absolute left-[13px] top-[-3px] z-[9999] flex h-[20px] w-[20px] items-center justify-center rounded-[50%] border border-solid border-red-900 bg-product-salePrice text-center text-Prefix font-normal text-white-400'>
                  {cart?.quantity && cart.quantity > 9 ? '9+' : cart.quantity}
                </p>

                <span className=''>{t('cart')}</span>
              </Link>
            </div>
            <div className='h-full items-center md:py-1 xl:py-2'>
              {user ? (
                <div className='inline-flex'>
                  <DropdownMenu.Root>
                    <DropdownMenu.Trigger asChild>
                      <div className='relative flex items-center justify-center gap-1'>
                        <AccountTrigger />
                        <span className='hover:cursor-pointer'>
                          {t('sayHi')}, {lastName}{' '}
                        </span>
                        <svg
                          className='relative left-[-2px]'
                          xmlns='http://www.w3.org/2000/svg'
                          width='8'
                          height='7'
                          viewBox='0 0 8 7'
                          fill='none'
                        >
                          <path
                            d='M4 6.56494L0.535899 0.564941L7.4641 0.564941L4 6.56494Z'
                            fill='#303538'
                          />
                        </svg>
                      </div>
                    </DropdownMenu.Trigger>

                    <DropdownMenu.Portal>
                      <DropdownMenu.Content
                        className='DropdownLanguageContent z-[99999] overflow-hidden'
                        sideOffset={15}
                        side='bottom'
                        align='end'
                        sticky='always'
                      >
                        <DropdownMenu.Item
                          onClick={() => router.push('/account?tab=account')}
                          className='DropdownAccountItem flex h-[40px] cursor-pointer items-center  border-t-[1px] border-grey-1200 text-sm font-normal text-dark-400 outline-none first:border-t-[0px] hover:bg-grey-200 hover:bg-sky-100 hover:text-blue-600'
                        >
                          <span className='px-3'>Thông tin tài khoản</span>
                        </DropdownMenu.Item>
                        <DropdownMenu.Item
                          onClick={() => setIsOpenListAddress(true)}
                          className='DropdownAccountItem flex h-[40px] cursor-pointer items-center  border-t-[1px] border-grey-1200 text-sm font-normal text-dark-400 outline-none first:border-t-[0px] hover:bg-grey-200 hover:bg-sky-100 hover:text-blue-600'
                        >
                          <span className='px-3'>Địa chỉ giao hàng</span>
                        </DropdownMenu.Item>
                        <DropdownMenu.Item
                          onClick={() => logout()}
                          className='DropdownAccountItem flex h-[40px] cursor-pointer items-center  border-t-[1px] border-grey-1200 text-sm font-normal text-dark-400 outline-none first:border-t-[0px] hover:bg-grey-200 hover:bg-sky-100 hover:text-blue-600'
                        >
                          <span className='px-3'>Đăng xuất</span>
                        </DropdownMenu.Item>
                      </DropdownMenu.Content>
                    </DropdownMenu.Portal>
                  </DropdownMenu.Root>
                </div>
              ) : (
                <Link
                  href='/login'
                  className='flex items-center justify-center gap-x-[12px]'
                >
                  <AccountTrigger></AccountTrigger>
                  <span>{t('login')}</span>
                </Link>
              )}
            </div>
            {page !== 'homepage' && (
              <>
                <ListAddressShipping
                  handleSelect={e => {
                    setAddress(e)
                    setIsOpenListAddress(false)
                    setIsOpenAddAddress(true)
                  }}
                  open={isOpenListAddress}
                  onClose={() => {
                    setIsOpenListAddress(false)
                  }}
                  handleAdd={() => {
                    setIsOpenListAddress(false)
                    setIsOpenAddAddress(true)
                  }}
                  address={userAddress}
                />
                <AddAddressShipping
                  userAddress={address}
                  open={isOpenAddAddress}
                  onClose={() => {
                    setIsOpenAddAddress(false)
                    setIsOpenListAddress(true)
                  }}
                />
              </>
            )}
          </div>
        </div>
      </div>
    </header>
  )
}
export { Header }
