export const BookMark = () => {
  return (
    <svg
      width={24}
      height={33}
      viewBox='0 0 24 33'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M7.5 15H16.5M7.5 21H16.5M7.5 9H16.5M1.5 3C1.5 2.60218 1.65804 2.22064 1.93934 1.93934C2.22064 1.65804 2.60218 1.5 3 1.5H21C21.3978 1.5 21.7794 1.65804 22.0607 1.93934C22.342 2.22064 22.5 2.60218 22.5 3V31.5L17.25 27.75L12 31.5L6.75 27.75L1.5 31.5V3Z'
        stroke='white'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
export const IconBack = () => {
  return (
    <svg
      width={12}
      height={20}
      viewBox='0 0 12 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M12 2.91414L9.7166 0.681641L0 10.1816L9.7166 19.6816L12 17.4491L4.583 10.1816L12 2.91414Z'
        fill='#555555'
      />
    </svg>
  )
}
export const Bep = () => {
  return (
    <svg
      width={24}
      height={24}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_1_10101)'>
        <path
          d='M23 8H19'
          stroke='#212121'
          strokeWidth={2}
          strokeMiterlimit={10}
          strokeLinecap='square'
        />
        <path
          d='M16 23H4C2.343 23 1 21.657 1 20V8H19V20C19 21.657 17.657 23 16 23Z'
          stroke='#212121'
          strokeWidth={2}
          strokeMiterlimit={10}
          strokeLinecap='square'
        />
        <path
          d='M1 13H19'
          stroke='#212121'
          strokeWidth={2}
          strokeMiterlimit={10}
          strokeLinecap='square'
        />
        <path
          d='M10 4V1'
          stroke='#212121'
          strokeWidth={2}
          strokeMiterlimit={10}
          strokeLinecap='square'
        />
        <path
          d='M5 4V1'
          stroke='#212121'
          strokeWidth={2}
          strokeMiterlimit={10}
          strokeLinecap='square'
        />
        <path
          d='M15 4V1'
          stroke='#212121'
          strokeWidth={2}
          strokeMiterlimit={10}
          strokeLinecap='square'
        />
      </g>
      <defs>
        <clipPath id='clip0_1_10101'>
          <rect width={24} height={24} fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}
export const RuaBat = () => {
  return (
    <svg
      width={24}
      height={24}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M23 2H1V23H23V2Z'
        stroke='#212121'
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap='square'
      />
      <path
        d='M8 11C9.65685 11 11 9.65685 11 8C11 6.34315 9.65685 5 8 5C6.34315 5 5 6.34315 5 8C5 9.65685 6.34315 11 8 11Z'
        stroke='#212121'
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap='square'
      />
      <path
        d='M8 20C9.65685 20 11 18.6569 11 17C11 15.3431 9.65685 14 8 14C6.34315 14 5 15.3431 5 17C5 18.6569 6.34315 20 8 20Z'
        stroke='#212121'
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap='square'
      />
      <path
        d='M19 6H17'
        stroke='#212121'
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap='square'
      />
      <path
        d='M19 10H17'
        stroke='#212121'
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap='square'
      />
      <path
        d='M19 15H17'
        stroke='#212121'
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap='square'
      />
      <path
        d='M19 19H17'
        stroke='#212121'
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap='square'
      />
    </svg>
  )
}
export const IconHome = () => {
  return (
    <svg
      width={23}
      height={24}
      viewBox='0 0 23 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M16.2787 23.6788H6.72043C5.6395 23.719 4.5864 23.3309 3.78994 22.5991C2.99348 21.8672 2.51801 20.8506 2.46683 19.7701V14.6461C2.46683 14.4283 2.55335 14.2194 2.70734 14.0654C2.86134 13.9114 3.0702 13.8249 3.28799 13.8249C3.50577 13.8249 3.71464 13.9114 3.86864 14.0654C4.02263 14.2194 4.10915 14.4283 4.10915 14.6461V19.7701C4.15962 20.4151 4.46178 21.0143 4.95037 21.4384C5.43896 21.8624 6.07476 22.0773 6.72043 22.0365H16.2787C16.9244 22.0773 17.5602 21.8624 18.0488 21.4384C18.5373 21.0143 18.8395 20.4151 18.89 19.7701V14.6461C18.89 14.4283 18.9765 14.2194 19.1305 14.0654C19.2845 13.9114 19.4934 13.8249 19.7111 13.8249C19.9289 13.8249 20.1378 13.9114 20.2918 14.0654C20.4458 14.2194 20.5323 14.4283 20.5323 14.6461V19.7701C20.4811 20.8506 20.0056 21.8672 19.2092 22.5991C18.4127 23.3309 17.3596 23.719 16.2787 23.6788ZM22.7576 12.7656C22.8346 12.6893 22.8957 12.5985 22.9374 12.4984C22.9791 12.3983 23.0005 12.291 23.0005 12.1826C23.0005 12.0742 22.9791 11.9669 22.9374 11.8668C22.8957 11.7667 22.8346 11.6759 22.7576 11.5996L12.0826 0.924538C12.0062 0.847572 11.9154 0.786482 11.8154 0.744793C11.7153 0.703104 11.608 0.681641 11.4996 0.681641C11.3912 0.681641 11.2838 0.703104 11.1838 0.744793C11.0837 0.786482 10.9929 0.847572 10.9165 0.924538L0.241495 11.5996C0.0868685 11.7542 0 11.9639 0 12.1826C0 12.4013 0.0868685 12.611 0.241495 12.7656C0.396122 12.9203 0.605842 13.0071 0.824517 13.0071C1.04319 13.0071 1.25291 12.9203 1.40754 12.7656L11.4996 2.66539L21.5916 12.7656C21.6679 12.8426 21.7587 12.9037 21.8588 12.9454C21.9589 12.9871 22.0662 13.0085 22.1746 13.0085C22.283 13.0085 22.3903 12.9871 22.4904 12.9454C22.5905 12.9037 22.6813 12.8426 22.7576 12.7656Z'
        fill='#282828'
      />
    </svg>
  )
}
export const LogoIonah = () => {
  return (
    <svg
      width={127}
      height={36}
      viewBox='0 0 127 37'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M4.25505 36.4934C1.96394 36.4934 0.0957031 34.6311 0.0957031 32.3473V14.5675C0.0957031 12.2837 1.96394 10.4214 4.25505 10.4214C6.54616 10.4214 8.41439 12.2837 8.41439 14.5675V32.3473C8.41439 34.6311 6.54616 36.4934 4.25505 36.4934Z'
        fill='black'
      />
      <path
        d='M122.744 36.4935C120.452 36.4935 118.584 34.6312 118.584 32.3474V23.2248C118.584 21.828 118.105 20.5383 117.227 19.6072C116.186 18.4936 114.949 17.9525 113.447 17.9525C111.944 17.9525 110.707 18.4936 109.666 19.6072C108.789 20.5383 108.309 21.8218 108.309 23.2248V32.3474C108.309 34.6312 106.441 36.4935 104.15 36.4935C101.858 36.4935 99.9902 34.6312 99.9902 32.3474V7.09336C99.9902 4.80955 101.858 2.94727 104.15 2.94727C106.441 2.94727 108.309 4.80955 108.309 7.09336V10.6355C109.893 9.99374 111.61 9.66658 113.447 9.66658C117.151 9.66658 120.351 10.9878 122.964 13.5862C125.577 16.1909 126.897 19.3807 126.897 23.0738V32.3411C126.903 34.6312 125.035 36.4935 122.744 36.4935Z'
        fill='black'
      />
      <path
        d='M83.698 36.2607C79.9931 36.2607 76.7869 34.9394 74.1802 32.3411C71.5672 29.7364 70.248 26.5466 70.248 22.8535C70.248 19.1604 71.5735 15.9643 74.1865 13.3659C76.7995 10.7612 79.9995 9.44629 83.7044 9.44629C87.4093 9.44629 90.6156 10.7675 93.2222 13.3722C95.8352 15.9769 97.1607 19.1667 97.1607 22.8598V32.5738C97.1607 33.3099 96.7946 34.3921 96.094 35.0275C95.2988 35.751 94.1942 36.1537 92.9887 36.1537C91.7516 36.1537 90.6282 35.7322 89.9023 35.0086C89.8582 34.9646 89.8203 34.9206 89.7761 34.8765C87.9458 35.7888 85.9071 36.2607 83.698 36.2607ZM83.698 17.7259C82.1517 17.7259 80.8831 18.2984 79.8227 19.4875C79.078 20.318 78.6298 21.3813 78.573 22.4949C78.4784 24.1999 79.0527 25.584 80.3277 26.7227C81.1608 27.4651 82.2274 27.9055 83.3383 27.9685C83.4582 27.9748 83.5781 27.981 83.698 27.981C85.2444 27.981 86.513 27.4085 87.5797 26.2194C88.3244 25.3889 88.7663 24.3257 88.8294 23.2184C88.9241 21.5134 88.3497 20.1293 87.0748 18.9905C86.2416 18.2481 85.175 17.8077 84.0641 17.7448C83.9316 17.7322 83.8117 17.7259 83.698 17.7259Z'
        fill='black'
      />
      <path
        d='M63.4954 36.4056C61.0086 36.4056 58.9826 34.386 58.9826 31.9072V22.967C58.9826 21.7968 58.6102 20.702 57.9412 19.8841C56.8304 18.5315 55.4923 17.8709 53.845 17.8709C53.6746 17.8709 53.5042 17.8772 53.3274 17.8897C52.3049 17.9652 51.314 18.3868 50.5377 19.0662C49.3069 20.1421 48.7073 21.4256 48.7073 22.9921V32.1777C48.7073 34.5056 46.8076 36.4056 44.466 36.4056C42.219 36.4056 40.3887 34.5811 40.3887 32.3413V22.9984C40.3887 19.3053 41.7141 16.1092 44.3208 13.5109C46.9338 10.9062 50.1338 9.58496 53.8387 9.58496C57.5436 9.58496 60.7499 10.9062 63.3565 13.5046C65.9695 16.1092 67.2887 19.299 67.2887 22.9921V32.6118C67.3013 34.7069 65.5972 36.4056 63.4954 36.4056Z'
        fill='black'
      />
      <path
        d='M35.0736 13.5926C32.4606 10.9879 29.2606 9.67297 25.5557 9.67297C23.8768 9.67297 22.3053 9.9498 20.841 10.4909C19.0295 11.1578 17.054 11.2333 15.2615 10.5097L9.65052 8.2448C9.13928 8.06864 8.89313 8.36434 9.16453 8.8362L11.7965 13.4542C12.8063 15.2284 13.1724 17.336 12.6107 19.299C12.2698 20.4943 12.0994 21.7589 12.0994 23.0864C12.0994 26.7796 13.4249 29.9756 16.0315 32.574C18.6445 35.1787 21.8445 36.4999 25.5494 36.4999C29.2543 36.4999 32.4606 35.1787 35.0673 32.5803C37.6803 29.9756 39.0057 26.7858 39.0057 23.0927C39.012 19.387 37.6866 16.191 35.0736 13.5926ZM29.1912 26.7041C28.175 27.7107 26.9885 28.2014 25.5557 28.2014C24.123 28.2014 22.9364 27.7107 21.9203 26.7041C20.9041 25.6911 20.4118 24.5083 20.4118 23.0802C20.4118 21.652 20.9041 20.4692 21.9203 19.4562C22.9364 18.4496 24.123 17.9589 25.5557 17.9589C26.9885 17.9589 28.175 18.4496 29.1912 19.4625C30.2011 20.4692 30.6934 21.6583 30.6934 23.0864C30.6934 24.5083 30.2011 25.6911 29.1912 26.7041Z'
        fill='url(#paint0_radial_12683_44538)'
      />
      <path
        d='M4.48123 0.5C3.23785 0.5 2.1775 0.934113 1.3065 1.80234C0.4355 2.67057 0 3.72754 0 4.96696C0 6.20639 0.4355 7.26336 1.3065 8.13159C2.1775 8.99981 3.23785 9.43392 4.48123 9.43392C5.72462 9.43392 6.78497 8.99981 7.65597 8.13159C8.52697 7.26336 8.96247 6.20639 8.96247 4.96696C8.96247 3.72754 8.52697 2.67057 7.65597 1.80234C6.79128 0.934113 5.73093 0.5 4.48123 0.5Z'
        fill='url(#paint1_radial_12683_44538)'
      />
      <path
        d='M4.25505 36.4934C1.96394 36.4934 0.0957031 34.6311 0.0957031 32.3473V14.5675C0.0957031 12.2837 1.96394 10.4214 4.25505 10.4214C6.54616 10.4214 8.41439 12.2837 8.41439 14.5675V32.3473C8.41439 34.6311 6.54616 36.4934 4.25505 36.4934Z'
        fill='white'
      />
      <path
        d='M122.744 36.4935C120.452 36.4935 118.584 34.6312 118.584 32.3474V23.2248C118.584 21.8281 118.105 20.5383 117.227 19.6072C116.186 18.4936 114.949 17.9525 113.447 17.9525C111.944 17.9525 110.707 18.4936 109.666 19.6072C108.789 20.5383 108.309 21.8218 108.309 23.2248V32.3474C108.309 34.6312 106.441 36.4935 104.15 36.4935C101.858 36.4935 99.9902 34.6312 99.9902 32.3474V7.09336C99.9902 4.80955 101.858 2.94727 104.15 2.94727C106.441 2.94727 108.309 4.80955 108.309 7.09336V10.6355C109.893 9.99374 111.61 9.66658 113.447 9.66658C117.151 9.66658 120.351 10.9878 122.964 13.5862C125.577 16.1909 126.897 19.3807 126.897 23.0738V32.3411C126.903 34.6312 125.035 36.4935 122.744 36.4935Z'
        fill='white'
      />
      <path
        d='M83.698 36.2607C79.9931 36.2607 76.7869 34.9394 74.1802 32.3411C71.5672 29.7364 70.248 26.5466 70.248 22.8535C70.248 19.1604 71.5735 15.9643 74.1865 13.3659C76.7995 10.7612 79.9995 9.44629 83.7044 9.44629C87.4093 9.44629 90.6156 10.7675 93.2222 13.3722C95.8352 15.9769 97.1607 19.1667 97.1607 22.8598V32.5738C97.1607 33.3099 96.7946 34.3921 96.094 35.0275C95.2988 35.751 94.1942 36.1537 92.9887 36.1537C91.7516 36.1537 90.6282 35.7322 89.9023 35.0086C89.8582 34.9646 89.8203 34.9206 89.7761 34.8765C87.9458 35.7888 85.9071 36.2607 83.698 36.2607ZM83.698 17.7259C82.1517 17.7259 80.8831 18.2984 79.8227 19.4875C79.078 20.318 78.6298 21.3813 78.573 22.4949C78.4784 24.1999 79.0527 25.584 80.3277 26.7227C81.1608 27.4651 82.2274 27.9055 83.3383 27.9685C83.4582 27.9748 83.5781 27.981 83.698 27.981C85.2444 27.981 86.513 27.4085 87.5797 26.2194C88.3244 25.3889 88.7663 24.3257 88.8294 23.2184C88.9241 21.5134 88.3497 20.1293 87.0748 18.9905C86.2416 18.2481 85.175 17.8077 84.0641 17.7448C83.9316 17.7322 83.8117 17.7259 83.698 17.7259Z'
        fill='white'
      />
      <path
        d='M63.4954 36.4056C61.0086 36.4056 58.9826 34.386 58.9826 31.9072V22.967C58.9826 21.7968 58.6102 20.702 57.9412 19.8841C56.8304 18.5315 55.4923 17.8709 53.845 17.8709C53.6746 17.8709 53.5042 17.8772 53.3274 17.8897C52.3049 17.9652 51.314 18.3868 50.5377 19.0662C49.3069 20.1421 48.7073 21.4256 48.7073 22.9921V32.1777C48.7073 34.5056 46.8076 36.4056 44.466 36.4056C42.219 36.4056 40.3887 34.5811 40.3887 32.3413V22.9984C40.3887 19.3053 41.7141 16.1092 44.3208 13.5109C46.9338 10.9062 50.1338 9.58496 53.8387 9.58496C57.5436 9.58496 60.7499 10.9062 63.3565 13.5046C65.9695 16.1092 67.2887 19.299 67.2887 22.9921V32.6118C67.3013 34.7069 65.5972 36.4056 63.4954 36.4056Z'
        fill='white'
      />
      <path
        d='M35.0736 13.5926C32.4606 10.9879 29.2606 9.67297 25.5557 9.67297C23.8768 9.67297 22.3053 9.9498 20.841 10.4909C19.0295 11.1578 17.054 11.2333 15.2615 10.5097L9.65052 8.2448C9.13928 8.06864 8.89313 8.36434 9.16453 8.8362L11.7965 13.4542C12.8063 15.2284 13.1724 17.336 12.6107 19.299C12.2698 20.4943 12.0994 21.7589 12.0994 23.0864C12.0994 26.7796 13.4249 29.9756 16.0315 32.574C18.6445 35.1787 21.8445 36.4999 25.5494 36.4999C29.2543 36.4999 32.4606 35.1787 35.0673 32.5803C37.6803 29.9756 39.0057 26.7858 39.0057 23.0927C39.012 19.387 37.6866 16.191 35.0736 13.5926ZM29.1912 26.7041C28.175 27.7107 26.9885 28.2014 25.5557 28.2014C24.123 28.2014 22.9364 27.7107 21.9203 26.7041C20.9041 25.6911 20.4118 24.5083 20.4118 23.0802C20.4118 21.652 20.9041 20.4692 21.9203 19.4562C22.9364 18.4496 24.123 17.9589 25.5557 17.9589C26.9885 17.9589 28.175 18.4496 29.1912 19.4625C30.2011 20.4692 30.6934 21.6583 30.6934 23.0864C30.6934 24.5083 30.2011 25.6911 29.1912 26.7041Z'
        fill='url(#paint2_radial_12683_44538)'
      />
      <defs>
        <radialGradient
          id='paint0_radial_12683_44538'
          cx='0'
          cy='0'
          r='1'
          gradientUnits='userSpaceOnUse'
          gradientTransform='translate(3.65811 3.30874) scale(49.9707 49.8116)'
        >
          <stop stopColor='#004C96' />
          <stop offset='0.00278669' stopColor='#004E96' />
          <stop offset='0.0705' stopColor='#00869B' />
          <stop offset='0.1333' stopColor='#00B29F' />
          <stop offset='0.1893' stopColor='#00D2A2' />
          <stop offset='0.2363' stopColor='#00E5A3' />
          <stop offset='0.2688' stopColor='#00ECA4' />
          <stop offset='0.6341' stopColor='#00B18C' />
          <stop offset='1' stopColor='#1E2492' />
        </radialGradient>
        <radialGradient
          id='paint1_radial_12683_44538'
          cx='0'
          cy='0'
          r='1'
          gradientUnits='userSpaceOnUse'
          gradientTransform='translate(3.81549 3.13041) scale(36.1413 36.0262)'
        >
          <stop stopColor='#4FFFA4' />
          <stop offset='0.0389' stopColor='#3FE79F' />
          <stop offset='0.1141' stopColor='#24BF97' />
          <stop offset='0.1827' stopColor='#10A191' />
          <stop offset='0.2417' stopColor='#048F8D' />
          <stop offset='0.2844' stopColor='#00898C' />
          <stop offset='0.3647' stopColor='#00778F' />
          <stop offset='0.4957' stopColor='#005F93' />
          <stop offset='0.6179' stopColor='#005195' />
          <stop offset='0.7218' stopColor='#004C96' />
        </radialGradient>
        <radialGradient
          id='paint2_radial_12683_44538'
          cx='0'
          cy='0'
          r='1'
          gradientUnits='userSpaceOnUse'
          gradientTransform='translate(3.65811 3.30874) scale(49.9707 49.8116)'
        >
          <stop stopColor='#00ECA4' />
          <stop offset='0.3946' stopColor='#00B1A6' />
          <stop offset='0.4002' stopColor='#00B0A7' />
          <stop offset='0.6579' stopColor='#006EE6' />
          <stop offset='0.7793' stopColor='#0055FF' />
          <stop offset='0.8184' stopColor='#064BF2' />
          <stop offset='0.9368' stopColor='#172FCE' />
          <stop offset='1' stopColor='#1E24C0' />
        </radialGradient>
      </defs>
    </svg>
  )
}
export const MiniLogo = () => {
  return (
    <svg
      width={30}
      height={28}
      viewBox='0 0 30 28'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M26.9708 9.82576C24.9638 7.87232 22.4982 6.8773 19.6493 6.8773C18.3564 6.8773 17.1537 7.08216 16.0262 7.49187C14.6356 7.99669 13.1172 8.04791 11.7341 7.5065L7.41941 5.80912C7.02853 5.67743 6.84061 5.90423 7.05108 6.25542L9.07312 9.72334C9.84735 11.0549 10.133 12.6425 9.69702 14.1131C9.43393 15.013 9.29862 15.9568 9.29862 16.9591C9.29862 19.732 10.3134 22.1318 12.3279 24.0852C14.3349 26.0387 16.8004 27.0337 19.6493 27.0337C22.4982 27.0337 24.9638 26.046 26.9708 24.0852C28.9778 22.1318 30 19.732 30 16.9591C29.9925 14.179 28.9778 11.7792 26.9708 9.82576ZM22.4456 19.6735C21.6639 20.4344 20.7543 20.8002 19.6493 20.8002C18.5519 20.8002 17.6348 20.4344 16.8531 19.6735C16.0713 18.9126 15.6955 18.0273 15.6955 16.9518C15.6955 15.8837 16.0713 14.9911 16.8531 14.2302C17.6348 13.4693 18.5444 13.1035 19.6493 13.1035C20.7468 13.1035 21.6639 13.4693 22.4456 14.2302C23.2274 14.9911 23.6032 15.8763 23.6032 16.9518C23.6032 18.02 23.2198 18.9126 22.4456 19.6735Z'
        fill='#004C93'
      />
      <path
        d='M3.45024 0C2.48808 0 1.67626 0.329233 1.00726 0.980383C0.338259 1.63153 0 2.42169 0 3.35818C0 4.28734 0.338259 5.08482 1.00726 5.73597C1.67626 6.38712 2.48808 6.71635 3.45024 6.71635C4.40488 6.71635 5.22422 6.38712 5.89322 5.73597C6.56222 5.08482 6.90047 4.29466 6.90047 3.35818C6.90047 2.42901 6.56222 1.63153 5.89322 0.980383C5.2167 0.329233 4.40488 0 3.45024 0Z'
        fill='url(#paint0_radial_2060_15718)'
      />
      <path
        d='M26.9708 9.82576C24.9638 7.87232 22.4982 6.8773 19.6493 6.8773C18.3564 6.8773 17.1537 7.08216 16.0262 7.49187C14.6356 7.99669 13.1172 8.04791 11.7341 7.5065L7.41941 5.80912C7.02853 5.67743 6.84061 5.90423 7.05108 6.25542L9.07312 9.72334C9.84735 11.0549 10.133 12.6425 9.69702 14.1131C9.43393 15.013 9.29862 15.9568 9.29862 16.9591C9.29862 19.732 10.3134 22.1318 12.3279 24.0852C14.3349 26.0387 16.8004 27.0337 19.6493 27.0337C22.4982 27.0337 24.9638 26.046 26.9708 24.0852C28.9778 22.1318 30 19.732 30 16.9591C29.9925 14.179 28.9778 11.7792 26.9708 9.82576ZM22.4456 19.6735C21.6639 20.4344 20.7543 20.8002 19.6493 20.8002C18.5519 20.8002 17.6348 20.4344 16.8531 19.6735C16.0713 18.9126 15.6955 18.0273 15.6955 16.9518C15.6955 15.8837 16.0713 14.9911 16.8531 14.2302C17.6348 13.4693 18.5444 13.1035 19.6493 13.1035C20.7468 13.1035 21.6639 13.4693 22.4456 14.2302C23.2274 14.9911 23.6032 15.8763 23.6032 16.9518C23.6032 18.02 23.2198 18.9126 22.4456 19.6735Z'
        fill='url(#paint1_radial_2060_15718)'
      />
      <defs>
        <radialGradient
          id='paint0_radial_2060_15718'
          cx={0}
          cy={0}
          r={1}
          gradientUnits='userSpaceOnUse'
          gradientTransform='translate(2.93368 1.97481) scale(27.7886 27.0471)'
        >
          <stop stopColor='#4FFFA1' />
          <stop offset='0.0428' stopColor='#3DE49C' />
          <stop offset='0.1168' stopColor='#23BC94' />
          <stop offset='0.1843' stopColor='#109E8E' />
          <stop offset='0.2424' stopColor='#048C8A' />
          <stop offset='0.2844' stopColor='#008689' />
          <stop offset='0.3542' stopColor='#00778C' />
          <stop offset='0.4891' stopColor='#005F90' />
          <stop offset='0.6149' stopColor='#005192' />
          <stop offset='0.7218' stopColor='#004C93' />
        </radialGradient>
        <radialGradient
          id='paint1_radial_2060_15718'
          cx={0}
          cy={0}
          r={1}
          gradientUnits='userSpaceOnUse'
          gradientTransform='translate(2.81293 2.10869) scale(38.422 37.3968)'
        >
          <stop stopColor='#00E9A1' />
          <stop offset='0.3946' stopColor='#00AEA3' />
          <stop offset='0.4002' stopColor='#00ADA4' />
          <stop offset='0.6579' stopColor='#006EE6' />
          <stop offset='0.7793' stopColor='#0055FF' />
          <stop offset='0.8252' stopColor='#0749EF' />
          <stop offset='0.9391' stopColor='#182ECB' />
          <stop offset={1} stopColor='#1E24BD' />
        </radialGradient>
      </defs>
    </svg>
  )
}
export const LogoIonahLight = () => {
  return (
    <svg viewBox='0 0 142 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_534_1186)'>
        <path
          d='M4.75884 40C2.18796 40 0.109375 37.9312 0.109375 35.3967V15.6404C0.109375 13.095 2.1989 11.0371 4.75884 11.0371C7.31877 11.0371 9.4083 13.1059 9.4083 15.6404V35.3967C9.4083 37.9421 7.32971 40 4.75884 40Z'
          fill='black'
        />
        <path
          d='M137.35 40C134.779 40 132.7 37.9312 132.7 35.3967V25.2586C132.7 23.6989 132.164 22.28 131.18 21.2402C130.009 20.0054 128.631 19.3989 126.946 19.3989C125.272 19.3989 123.883 19.9946 122.712 21.2402C121.728 22.28 121.191 23.6989 121.191 25.2586V35.3967C121.191 37.9421 119.102 40 116.542 40C113.971 40 111.893 37.9312 111.893 35.3967V7.3328C111.893 4.78744 113.982 2.72949 116.542 2.72949C119.113 2.72949 121.191 4.79827 121.191 7.3328V11.2754C122.964 10.5605 124.889 10.1923 126.946 10.1923C131.092 10.1923 134.68 11.6545 137.601 14.5573C140.522 17.4492 142.01 21.0019 142.01 25.107V35.4075C141.999 37.9421 139.91 40 137.35 40Z'
          fill='#231F20'
        />
        <path
          d='M93.6561 39.7509C89.5098 39.7509 85.9215 38.2887 83.0006 35.3859C80.0796 32.4939 78.5918 28.9412 78.5918 24.8362C78.5918 20.7311 80.0687 17.1785 83.0006 14.2865C85.9215 11.3945 89.5098 9.92148 93.6561 9.92148C97.8023 9.92148 101.391 11.3837 104.312 14.2865C107.232 17.1785 108.72 20.7311 108.72 24.8362V35.635C108.72 36.4582 108.316 37.6604 107.528 38.3645C106.642 39.166 105.406 39.6101 104.06 39.6101C102.671 39.6101 101.412 39.1443 100.614 38.332C100.57 38.2887 100.526 38.2345 100.472 38.1803C98.4149 39.2202 96.1285 39.7509 93.6561 39.7509ZM93.6561 19.1497C91.9276 19.1497 90.5054 19.7888 89.3129 21.0994C88.4815 22.02 87.9782 23.2115 87.9126 24.4354C87.8032 26.3309 88.4487 27.8689 89.8818 29.1362C90.8117 29.9594 92.0041 30.4576 93.2513 30.5226C93.3826 30.5334 93.5248 30.5334 93.6561 30.5334C95.3846 30.5334 96.8068 29.8944 97.9992 28.573C98.8306 27.6523 99.3339 26.4717 99.3995 25.2369C99.5089 23.3415 98.8635 21.8034 97.4303 20.5362C96.5004 19.713 95.308 19.2256 94.0608 19.1497C93.9296 19.1497 93.7873 19.1497 93.6561 19.1497Z'
          fill='#231F20'
        />
        <path
          d='M71.0553 39.9133C68.2766 39.9133 66.012 37.6713 66.012 34.9201V24.977C66.012 23.6772 65.5963 22.4641 64.8414 21.5543C63.6052 20.0487 62.0955 19.3122 60.2576 19.3122C60.0716 19.3122 59.8747 19.323 59.6778 19.3339C58.54 19.4205 57.4242 19.8863 56.549 20.6445C55.1706 21.8359 54.5032 23.2656 54.5032 25.0095V35.2234C54.5032 37.8121 52.3699 39.9242 49.7553 39.9242C47.2391 39.9242 45.1934 37.8987 45.1934 35.4075V25.0203C45.1934 20.9152 46.6702 17.3626 49.5912 14.4706C52.5122 11.5787 56.1004 10.1056 60.2467 10.1056C64.3929 10.1056 67.9812 11.5678 70.9021 14.4706C73.8231 17.3626 75.3 20.9152 75.3 25.0203V35.7108C75.3109 38.0179 73.4074 39.9133 71.0553 39.9133Z'
          fill='#231F20'
        />
        <path
          d='M39.2527 14.5464C36.3318 11.6545 32.7435 10.1814 28.5972 10.1814C26.7156 10.1814 24.9652 10.4847 23.3242 11.0913C21.3003 11.8386 19.0905 11.9144 17.0775 11.1129L10.798 8.60006C10.2291 8.40509 9.95565 8.74087 10.262 9.26077L13.2048 14.3948C14.3316 16.3661 14.7473 18.7165 14.1128 20.8936C13.7299 22.2258 13.533 23.6231 13.533 25.107C13.533 29.212 15.0099 32.7647 17.9418 35.6566C20.8627 38.5486 24.451 40.0217 28.5972 40.0217C32.7435 40.0217 36.3318 38.5594 39.2527 35.6566C42.1737 32.7647 43.6615 29.212 43.6615 25.107C43.6506 20.9911 42.1737 17.4384 39.2527 14.5464ZM32.6669 29.1254C31.5291 30.2518 30.2054 30.7934 28.5972 30.7934C27 30.7934 25.6654 30.2518 24.5276 29.1254C23.3899 27.9989 22.8429 26.6883 22.8429 25.0961C22.8429 23.5148 23.3899 22.1933 24.5276 21.0669C25.6654 19.9404 26.9891 19.3989 28.5972 19.3989C30.1945 19.3989 31.5291 19.9404 32.6669 21.0669C33.8046 22.1933 34.3516 23.5039 34.3516 25.0961C34.3516 26.6775 33.7937 27.9989 32.6669 29.1254Z'
          fill='url(#paint0_radial_534_1186)'
        />
        <path
          d='M5.02142 0C3.62111 0 2.4396 0.487409 1.46595 1.45139C0.492296 2.41538 0 3.58516 0 4.97157C0 6.34714 0.492296 7.52776 1.46595 8.49174C2.4396 9.45573 3.62111 9.94314 5.02142 9.94314C6.41079 9.94314 7.60324 9.45573 8.57689 8.49174C9.55054 7.52776 10.0428 6.35797 10.0428 4.97157C10.0428 3.59599 9.55054 2.41538 8.57689 1.45139C7.5923 0.487409 6.41079 0 5.02142 0Z'
          fill='url(#paint1_radial_534_1186)'
        />
        <path
          d='M4.75884 40C2.18796 40 0.109375 37.9312 0.109375 35.3967V15.6404C0.109375 13.095 2.1989 11.0371 4.75884 11.0371C7.31877 11.0371 9.4083 13.1059 9.4083 15.6404V35.3967C9.4083 37.9421 7.32971 40 4.75884 40Z'
          fill='#161616'
        />
        <path
          d='M137.35 40C134.779 40 132.7 37.9312 132.7 35.3967V25.2586C132.7 23.6989 132.164 22.28 131.18 21.2402C130.009 20.0054 128.631 19.3989 126.946 19.3989C125.272 19.3989 123.883 19.9946 122.712 21.2402C121.728 22.28 121.191 23.6989 121.191 25.2586V35.3967C121.191 37.9421 119.102 40 116.542 40C113.971 40 111.893 37.9312 111.893 35.3967V7.3328C111.893 4.78744 113.982 2.72949 116.542 2.72949C119.113 2.72949 121.191 4.79827 121.191 7.3328V11.2754C122.964 10.5605 124.889 10.1923 126.946 10.1923C131.092 10.1923 134.68 11.6545 137.601 14.5573C140.522 17.4492 142.01 21.0019 142.01 25.107V35.4075C141.999 37.9421 139.91 40 137.35 40Z'
          fill='#161616'
        />
        <path
          d='M93.6561 39.7509C89.5098 39.7509 85.9215 38.2887 83.0006 35.3859C80.0796 32.4939 78.5918 28.9412 78.5918 24.8362C78.5918 20.7311 80.0687 17.1785 83.0006 14.2865C85.9215 11.3945 89.5098 9.92148 93.6561 9.92148C97.8023 9.92148 101.391 11.3837 104.312 14.2865C107.232 17.1785 108.72 20.7311 108.72 24.8362V35.635C108.72 36.4582 108.316 37.6604 107.528 38.3645C106.642 39.166 105.406 39.6101 104.06 39.6101C102.671 39.6101 101.412 39.1443 100.614 38.332C100.57 38.2887 100.526 38.2345 100.472 38.1803C98.4149 39.2202 96.1285 39.7509 93.6561 39.7509ZM93.6561 19.1497C91.9276 19.1497 90.5054 19.7888 89.3129 21.0994C88.4815 22.02 87.9782 23.2115 87.9126 24.4354C87.8032 26.3309 88.4487 27.8689 89.8818 29.1362C90.8117 29.9594 92.0041 30.4576 93.2513 30.5226C93.3826 30.5334 93.5248 30.5334 93.6561 30.5334C95.3846 30.5334 96.8068 29.8944 97.9992 28.573C98.8306 27.6523 99.3339 26.4717 99.3995 25.2369C99.5089 23.3415 98.8635 21.8034 97.4303 20.5362C96.5004 19.713 95.308 19.2256 94.0608 19.1497C93.9296 19.1497 93.7873 19.1497 93.6561 19.1497Z'
          fill='#161616'
        />
        <path
          d='M71.0553 39.9133C68.2766 39.9133 66.012 37.6713 66.012 34.9201V24.977C66.012 23.6772 65.5963 22.4641 64.8414 21.5543C63.6052 20.0487 62.0955 19.3122 60.2576 19.3122C60.0716 19.3122 59.8747 19.323 59.6778 19.3339C58.54 19.4205 57.4242 19.8863 56.549 20.6445C55.1706 21.8359 54.5032 23.2656 54.5032 25.0095V35.2234C54.5032 37.8121 52.3699 39.9242 49.7553 39.9242C47.2391 39.9242 45.1934 37.8987 45.1934 35.4075V25.0203C45.1934 20.9152 46.6702 17.3626 49.5912 14.4706C52.5122 11.5787 56.1004 10.1056 60.2467 10.1056C64.3929 10.1056 67.9812 11.5678 70.9021 14.4706C73.8231 17.3626 75.3 20.9152 75.3 25.0203V35.7108C75.3109 38.0179 73.4074 39.9133 71.0553 39.9133Z'
          fill='#161616'
        />
        <path
          d='M39.2527 14.5464C36.3318 11.6545 32.7435 10.1814 28.5972 10.1814C26.7156 10.1814 24.9652 10.4847 23.3242 11.0913C21.3003 11.8386 19.0905 11.9144 17.0775 11.1129L10.798 8.60006C10.2291 8.40509 9.95565 8.74087 10.262 9.26077L13.2048 14.3948C14.3316 16.3661 14.7473 18.7165 14.1128 20.8936C13.7299 22.2258 13.533 23.6231 13.533 25.107C13.533 29.212 15.0099 32.7647 17.9418 35.6566C20.8627 38.5486 24.451 40.0217 28.5972 40.0217C32.7435 40.0217 36.3318 38.5594 39.2527 35.6566C42.1737 32.7647 43.6615 29.212 43.6615 25.107C43.6506 20.9911 42.1737 17.4384 39.2527 14.5464ZM32.6669 29.1254C31.5291 30.2518 30.2054 30.7934 28.5972 30.7934C27 30.7934 25.6654 30.2518 24.5276 29.1254C23.3899 27.9989 22.8429 26.6883 22.8429 25.0961C22.8429 23.5148 23.3899 22.1933 24.5276 21.0669C25.6654 19.9404 26.9891 19.3989 28.5972 19.3989C30.1945 19.3989 31.5291 19.9404 32.6669 21.0669C33.8046 22.1933 34.3516 23.5039 34.3516 25.0961C34.3516 26.6775 33.7937 27.9989 32.6669 29.1254Z'
          fill='url(#paint2_radial_534_1186)'
        />
      </g>
      <defs>
        <radialGradient
          id='paint0_radial_534_1186'
          cx={0}
          cy={0}
          r={1}
          gradientUnits='userSpaceOnUse'
          gradientTransform='translate(4.09383 3.1218) scale(55.9187 55.3636)'
        >
          <stop stopColor='#004C93' />
          <stop offset='0.068' stopColor='#008398' />
          <stop offset='0.1316' stopColor='#00AF9C' />
          <stop offset='0.1882' stopColor='#00CF9F' />
          <stop offset='0.2359' stopColor='#00E2A0' />
          <stop offset='0.2688' stopColor='#00E9A1' />
          <stop offset='0.6341' stopColor='#00AE89' />
          <stop offset={1} stopColor='#1E248F' />
        </radialGradient>
        <radialGradient
          id='paint1_radial_534_1186'
          cx={0}
          cy={0}
          r={1}
          gradientUnits='userSpaceOnUse'
          gradientTransform='translate(4.26963 2.92359) scale(40.4431 40.0416)'
        >
          <stop stopColor='#4FFFA1' />
          <stop offset='0.0428' stopColor='#3DE49C' />
          <stop offset='0.1168' stopColor='#23BC94' />
          <stop offset='0.1843' stopColor='#109E8E' />
          <stop offset='0.2424' stopColor='#048C8A' />
          <stop offset='0.2844' stopColor='#008689' />
          <stop offset='0.3542' stopColor='#00778C' />
          <stop offset='0.4891' stopColor='#005F90' />
          <stop offset='0.6149' stopColor='#005192' />
          <stop offset='0.7218' stopColor='#004C93' />
        </radialGradient>
        <radialGradient
          id='paint2_radial_534_1186'
          cx={0}
          cy={0}
          r={1}
          gradientUnits='userSpaceOnUse'
          gradientTransform='translate(4.09383 3.1218) scale(55.9187 55.3636)'
        >
          <stop stopColor='#00E9A1' />
          <stop offset='0.3946' stopColor='#00AEA3' />
          <stop offset='0.4002' stopColor='#00ADA4' />
          <stop offset='0.6579' stopColor='#006EE6' />
          <stop offset='0.7793' stopColor='#0055FF' />
          <stop offset='0.8252' stopColor='#0749EF' />
          <stop offset='0.9391' stopColor='#182ECB' />
          <stop offset={1} stopColor='#1E24BD' />
        </radialGradient>
        <clipPath id='clip0_534_1186'>
          <rect width={142} height={40} fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}
export const Heart = () => {
  return (
    <svg
      width={24}
      height={24}
      viewBox='0 0 32 29'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M28.423 3.79859C27.697 3.06987 26.8349 2.4918 25.8861 2.0974C24.9373 1.703 23.9203 1.5 22.8933 1.5C21.8663 1.5 20.8493 1.703 19.9005 2.0974C18.9517 2.4918 18.0896 3.06987 17.3636 3.79859L15.8568 5.31023L14.3499 3.79859C12.8834 2.32732 10.8943 1.50076 8.82022 1.50076C6.74617 1.50076 4.75706 2.32732 3.29049 3.79859C1.82391 5.26986 1 7.26534 1 9.34603C1 11.4267 1.82391 13.4222 3.29049 14.8935L4.7973 16.4051L15.8568 27.5L26.9162 16.4051L28.423 14.8935C29.1494 14.1651 29.7256 13.3003 30.1188 12.3484C30.5119 11.3966 30.7143 10.3764 30.7143 9.34603C30.7143 8.31571 30.5119 7.29548 30.1188 6.34363C29.7256 5.39178 29.1494 4.52697 28.423 3.79859Z'
        stroke='{stroke}'
        strokeWidth='{2}'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export const Downs = () => {
  return (
    <svg
      width={8}
      height={6}
      viewBox='0 0 8 6'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M7.943 0.269C7.90079 0.187965 7.83717 0.120046 7.75907 0.0726213C7.68097 0.025197 7.59137 8.04183e-05 7.5 0H0.5C0.408544 7.81986e-05 0.318862 0.0252388 0.240712 0.0727439C0.162562 0.120249 0.0989375 0.188279 0.0567636 0.26943C0.0145897 0.350581 -0.0045187 0.441746 0.00151773 0.533002C0.00755416 0.624258 0.0385044 0.712111 0.091 0.787L3.591 5.787C3.63714 5.85261 3.69839 5.90616 3.76957 5.94313C3.84076 5.98009 3.91979 5.99939 4 5.99939C4.08021 5.99939 4.15924 5.98009 4.23043 5.94313C4.30161 5.90616 4.36286 5.85261 4.409 5.787L7.909 0.787C7.96154 0.712043 7.9925 0.6241 7.99849 0.532758C8.00449 0.441417 7.98529 0.350182 7.943 0.269Z'
        fill='#2f343733'
      />
    </svg>
  )
}
export const IconCartHome = () => {
  return (
    <svg
      width={32}
      height={32}
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M8 31C9.65685 31 11 29.6569 11 28C11 26.3431 9.65685 25 8 25C6.34315 25 5 26.3431 5 28C5 29.6569 6.34315 31 8 31Z'
        stroke='#212121'
        strokeWidth='1.5'
        strokeMiterlimit={10}
        strokeLinecap='square'
      />
      <path
        d='M27 31C28.6569 31 30 29.6569 30 28C30 26.3431 28.6569 25 27 25C25.3431 25 24 26.3431 24 28C24 29.6569 25.3431 31 27 31Z'
        stroke='#212121'
        strokeWidth='1.5'
        strokeMiterlimit={10}
        strokeLinecap='square'
      />
      <path
        d='M5.706 7H30L27.508 18.629C27.212 20.012 25.989 21 24.575 21H9.715C8.174 21 6.883 19.832 6.73 18.299L5 1H1'
        stroke='#212121'
        strokeWidth='1.5'
        strokeMiterlimit={10}
        strokeLinecap='square'
      />
    </svg>
  )
}
export const IconsDownsLocate = () => {
  return (
    <svg
      width='8'
      height='5'
      viewBox='0 0 8 5'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path d='M4 5L7.4641 0.714286H0.535898L4 5Z' fill='black' />
    </svg>
  )
}
export const SearchIcon = ({ className = '' }: { className?: string }) => {
  return (
    <img
      src='/assets/icons/search-white.svg'
      alt='Tìm kiếm'
      className={className}
    />
  )
}

export const SearchIconCompare = ({
  className = '',
}: {
  className?: string
}) => {
  return (
    <svg
      className={className}
      width='20'
      height='21'
      viewBox='0 0 20 21'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M14.704 13.7494C15.9141 12.095 16.4552 10.0449 16.2191 8.00883C15.9831 5.97277 14.9873 4.1008 13.4308 2.76714C11.8743 1.43348 9.87186 0.736394 7.82368 0.81523C5.7755 0.894066 3.83253 1.74302 2.38317 3.19237C0.933812 4.64173 0.0848621 6.5847 0.0060259 8.63289C-0.0728103 10.6811 0.624276 12.6835 1.95794 14.24C3.2916 15.7965 5.16356 16.7923 7.19963 17.0283C9.23569 17.2644 11.2858 16.7233 12.9402 15.5132L17.8861 20.4591C18.1213 20.6863 18.4364 20.812 18.7635 20.8092C19.0905 20.8063 19.4034 20.6751 19.6347 20.4439C19.8659 20.2126 19.9971 19.8997 20 19.5727C20.0028 19.2456 19.8771 18.9305 19.6498 18.6953L14.704 13.7494ZM2.55207 8.97447C2.55207 7.86428 2.88128 6.77903 3.49807 5.85595C4.11485 4.93286 4.99151 4.21341 6.01719 3.78856C7.04286 3.36371 8.17149 3.25255 9.26034 3.46913C10.3492 3.68572 11.3494 4.22032 12.1344 5.00534C12.9194 5.79036 13.454 6.79054 13.6706 7.87939C13.8872 8.96824 13.776 10.0969 13.3512 11.1225C12.9263 12.1482 12.2069 13.0249 11.2838 13.6417C10.3607 14.2584 9.27545 14.5877 8.16526 14.5877C6.67706 14.586 5.25028 13.9941 4.19796 12.9418C3.14564 11.8894 2.55372 10.4627 2.55207 8.97447Z'
        fill='#303538'
      />
    </svg>
  )
}

export const PinIcon = ({ className = '' }: { className?: string }) => {
  return (
    <svg
      className={className}
      width='20'
      height='21'
      viewBox='0 0 20 21'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_584_5279)'>
        <path
          d='M10 0.677734C8.01169 0.680372 6.10556 1.4714 4.69961 2.87735C3.29366 4.2833 2.50264 6.18942 2.5 8.17774C2.5 13.5621 9.4875 20.3059 9.78437 20.5902C9.84212 20.6464 9.91947 20.6778 10 20.6778C10.0805 20.6778 10.1579 20.6464 10.2156 20.5902C10.5125 20.3059 17.5 13.5621 17.5 8.17774C17.4974 6.18942 16.7063 4.2833 15.3004 2.87735C13.8944 1.4714 11.9883 0.680372 10 0.677734ZM10 11.6152C9.32013 11.6152 8.65552 11.4136 8.09023 11.0359C7.52493 10.6582 7.08434 10.1213 6.82416 9.49321C6.56399 8.86509 6.49591 8.17392 6.62855 7.50711C6.76119 6.8403 7.08858 6.2278 7.56932 5.74706C8.05006 5.26631 8.66257 4.93892 9.32938 4.80629C9.99619 4.67365 10.6874 4.74172 11.3155 5.0019C11.9436 5.26207 12.4805 5.70267 12.8582 6.26796C13.2359 6.83326 13.4375 7.49786 13.4375 8.17774C13.437 9.08925 13.0746 9.96327 12.4301 10.6078C11.7855 11.2523 10.9115 11.6147 10 11.6152Z'
          fill='#1E42C0'
        />
      </g>
      <defs>
        <clipPath id='clip0_584_5279'>
          <rect
            width='20'
            height='20'
            fill='white'
            transform='translate(0 0.677734)'
          />
        </clipPath>
      </defs>
    </svg>
  )
}
export const IconDelivery = () => {
  return (
    <svg
      width={32}
      height={32}
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect y={4} width={20} height='1.5' fill='black' />
      <rect
        x={20}
        y={4}
        width={21}
        height='1.5'
        transform='rotate(90 20 4)'
        fill='black'
      />
      <rect y={24} width={20} height='1.5' fill='black' />
      <path
        d='M19.25 9.75H24C28.0041 9.75 31.25 12.9959 31.25 17V24.75H19.25V9.75Z'
        stroke='black'
        strokeWidth='1.5'
      />
      <circle
        cx='9.25'
        cy='25.25'
        r='2.5'
        fill='white'
        stroke='black'
        strokeWidth='1.5'
      />
      <circle
        cx='25.25'
        cy='25.25'
        r='2.5'
        fill='white'
        stroke='black'
        strokeWidth='1.5'
      />
    </svg>
  )
}
export const IconDowns = () => {
  return (
    <svg
      width={12}
      height={12}
      viewBox='0 0 12 12'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M9.97481 4.4709L6.12513 8.32058L2.48236 4.6778'
        stroke='white'
        strokeWidth={2}
        strokeLinecap='round'
      />
    </svg>
  )
}
export const IconLocation = () => {
  return (
    <svg
      width={18}
      height={24}
      viewBox='0 0 18 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M17 9C17 13.9 9 22 9 22C9 22 1 13.9 1 9C1 3.9 5.1 1 9 1C12.9 1 17 3.9 17 9Z'
        stroke='#212121'
        strokeWidth='1.5'
        strokeMiterlimit={10}
        strokeLinecap='square'
      />
      <path
        d='M9 12C10.6569 12 12 10.6569 12 9C12 7.34315 10.6569 6 9 6C7.34315 6 6 7.34315 6 9C6 10.6569 7.34315 12 9 12Z'
        stroke='#212121'
        strokeWidth='1.5'
        strokeMiterlimit={10}
        strokeLinecap='square'
      />
    </svg>
  )
}
export const IconSearch = () => {
  return (
    <svg
      width={24}
      height={24}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g opacity='0.5'>
        <path
          d='M22 22L15.656 15.656'
          stroke='#303538'
          strokeWidth='{2}'
          strokeMiterlimit='{10}'
          strokeLinecap='square'
        />
        <path
          d='M10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18Z'
          stroke='#303538'
          strokeWidth='{2}'
          strokeMiterlimit='{10}'
          strokeLinecap='square'
        />
      </g>
    </svg>
  )
}
export const Notification = () => {
  return (
    <svg
      width={27}
      height={30}
      viewBox='0 0 27 30'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M21.5 9.66602C21.5 7.54428 20.6571 5.50945 19.1569 4.00916C17.6566 2.50887 15.6217 1.66602 13.5 1.66602C11.3783 1.66602 9.34344 2.50887 7.84315 4.00916C6.34286 5.50945 5.5 7.54428 5.5 9.66602C5.5 18.9993 1.5 21.666 1.5 21.666H25.5C25.5 21.666 21.5 18.9993 21.5 9.66602Z'
        stroke='#ffffff'
        strokeWidth={2}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M15.8067 27C15.5723 27.4041 15.2358 27.7395 14.831 27.9727C14.4262 28.2059 13.9672 28.3286 13.5 28.3286C13.0329 28.3286 12.5739 28.2059 12.1691 27.9727C11.7642 27.7395 11.4278 27.4041 11.1934 27'
        stroke='#ffffff'
        strokeWidth={2}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
export const MenuHeader = () => {
  return (
    <svg
      width={16}
      height={16}
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M15 7H1C0.4 7 0 7.4 0 8C0 8.6 0.4 9 1 9H15C15.6 9 16 8.6 16 8C16 7.4 15.6 7 15 7Z'
        fill='#212121'
      />
      <path
        d='M15 1H1C0.4 1 0 1.4 0 2C0 2.6 0.4 3 1 3H15C15.6 3 16 2.6 16 2C16 1.4 15.6 1 15 1Z'
        fill='#212121'
      />
      <path
        d='M15 13H1C0.4 13 0 13.4 0 14C0 14.6 0.4 15 1 15H15C15.6 15 16 14.6 16 14C16 13.4 15.6 13 15 13Z'
        fill='#212121'
      />
    </svg>
  )
}
export const IconCartHomeMobi = () => {
  return (
    <svg
      width={24}
      height={23}
      viewBox='0 0 24 23'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M6 22C6.55228 22 7 21.5523 7 21C7 20.4477 6.55228 20 6 20C5.44772 20 5 20.4477 5 21C5 21.5523 5.44772 22 6 22Z'
        fill='#303538'
      />
      <path
        d='M20 22C20.5523 22 21 21.5523 21 21C21 20.4477 20.5523 20 20 20C19.4477 20 19 20.4477 19 21C19 21.5523 19.4477 22 20 22Z'
        fill='#303538'
      />
      <path
        d='M6 22C6.55228 22 7 21.5523 7 21C7 20.4477 6.55228 20 6 20C5.44772 20 5 20.4477 5 21C5 21.5523 5.44772 22 6 22Z'
        stroke='#303538'
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap='square'
      />
      <path
        d='M20 22C20.5523 22 21 21.5523 21 21C21 20.4477 20.5523 20 20 20C19.4477 20 19 20.4477 19 21C19 21.5523 19.4477 22 20 22Z'
        stroke='#303538'
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap='square'
      />
      <path
        d='M4.8 6H22L20 16H6L4 1H1'
        stroke='#303538'
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap='square'
      />
    </svg>
  )
}
////////////////////Delete Later/////////////////////
export const TiviIcon = () => {
  return (
    <svg
      width={24}
      height={24}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M7 22H17'
        stroke='#212121'
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap='square'
      />
      <path
        d='M23 2H1V18H23V2Z'
        stroke='#212121'
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap='square'
      />
    </svg>
  )
}
export const AirCondIcon = () => {
  return (
    <svg
      width={24}
      height={24}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M21 13H3C1.89543 13 1 13.8954 1 15V20C1 21.1046 1.89543 22 3 22H21C22.1046 22 23 21.1046 23 20V15C23 13.8954 22.1046 13 21 13Z'
        stroke='#212121'
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap='square'
      />
      <path
        d='M19 18H5'
        stroke='#212121'
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap='square'
      />
      <path
        d='M12 7V2'
        stroke='#212121'
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap='square'
      />
      <path
        d='M7 9V6'
        stroke='#212121'
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap='square'
      />
      <path
        d='M17 9V6'
        stroke='#212121'
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap='square'
      />
    </svg>
  )
}
export const FridgeIcon = () => {
  return (
    <svg
      width={24}
      height={24}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M21 1H3V23H21V1Z'
        stroke='#212121'
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap='square'
      />
      <path
        d='M21 10H3'
        stroke='#212121'
        strokeWidth={2}
        strokeMiterlimit={10}
      />
      <path
        d='M7 5V6'
        stroke='#212121'
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap='square'
      />
      <path
        d='M7 14V17'
        stroke='#212121'
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap='square'
      />
    </svg>
  )
}
export const WashingIcon = () => {
  return (
    <svg
      width={24}
      height={24}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M21 23V1L3 1V23H21Z'
        stroke='#212121'
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap='square'
      />
      <path
        d='M12 19C14.7614 19 17 16.7614 17 14C17 11.2386 14.7614 9 12 9C9.23858 9 7 11.2386 7 14C7 16.7614 9.23858 19 12 19Z'
        stroke='#212121'
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap='square'
      />
      <path
        d='M7 5H10'
        stroke='#212121'
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap='square'
      />
      <path
        d='M16 5H17'
        stroke='#212121'
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap='square'
      />
    </svg>
  )
}
export const HobIcon = () => {
  return (
    <svg
      width={24}
      height={24}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M23 2H1V23H23V2Z'
        stroke='#212121'
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap='square'
      />
      <path
        d='M8 11C9.65685 11 11 9.65685 11 8C11 6.34315 9.65685 5 8 5C6.34315 5 5 6.34315 5 8C5 9.65685 6.34315 11 8 11Z'
        stroke='#212121'
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap='square'
      />
      <path
        d='M8 20C9.65685 20 11 18.6569 11 17C11 15.3431 9.65685 14 8 14C6.34315 14 5 15.3431 5 17C5 18.6569 6.34315 20 8 20Z'
        stroke='#212121'
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap='square'
      />
      <path
        d='M19 6H17'
        stroke='#212121'
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap='square'
      />
      <path
        d='M19 10H17'
        stroke='#212121'
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap='square'
      />
      <path
        d='M19 15H17'
        stroke='#212121'
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap='square'
      />
      <path
        d='M19 19H17'
        stroke='#212121'
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap='square'
      />
    </svg>
  )
}
export const MicrowaveIcon = () => {
  return (
    <svg
      width={24}
      height={24}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M23 3H1V21H23V3Z'
        stroke='#212121'
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap='square'
      />
      <path
        d='M18 16H19'
        stroke='#212121'
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap='square'
      />
      <path
        d='M18 12H19'
        stroke='#212121'
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap='square'
      />
      <path
        d='M18 8H19'
        stroke='#212121'
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap='square'
      />
      <path
        d='M14 8H5V16H14V8Z'
        stroke='#212121'
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap='square'
      />
    </svg>
  )
}
export const SelectedIcon = () => {
  return (
    <svg
      width='16'
      height='17'
      viewBox='0 0 16 17'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle cx='8' cy='8.75' r='8' fill='#FF0000' />
      <g clipPath='url(#clip0_584_5007)'>
        <path
          d='M12.5176 5.13463C12.2082 4.82482 11.7058 4.82501 11.396 5.13463L6.34787 10.183L4.10419 7.9393C3.79438 7.62949 3.29217 7.62949 2.98236 7.9393C2.67255 8.24912 2.67255 8.75132 2.98236 9.06113L5.78684 11.8656C5.94165 12.0204 6.14464 12.098 6.34765 12.098C6.55067 12.098 6.75386 12.0206 6.90867 11.8656L12.5176 6.25644C12.8275 5.94684 12.8275 5.44443 12.5176 5.13463Z'
          fill='white'
        />
      </g>
      <defs>
        <clipPath id='clip0_584_5007'>
          <rect
            width='10'
            height='10'
            fill='white'
            transform='translate(2.75 3.5)'
          />
        </clipPath>
      </defs>
    </svg>
  )
}
export const IconPromotion = () => {
  return <img src='/assets/icons/header/promotion.svg' alt='Khuyến mãi' />
}
