/* eslint-disable @typescript-eslint/no-explicit-any */

import { useAuth, useStore } from '@/containers'
import { Dialog, Transition } from '@headlessui/react'
import Image from 'next/image'
import { Fragment, useEffect, useState } from 'react'

interface ModalChooseLocation {
  productName: string
  productId: string
}

const ModalChooseLocation = () => {
  const { setRefetch } = useAuth()
  const {
    regions,
    saleRegion,
    setSaleRegion,
    isOpenPopupLocation,
    setIsOpenPopupLocation,
  } = useStore()
  const [choose, setChoose] = useState(saleRegion)
  useEffect(() => {
    if (saleRegion) {
      setChoose(saleRegion)
    }
  }, [saleRegion])
  return (
    <Transition appear show={isOpenPopupLocation} as={Fragment}>
      <Dialog
        as='div'
        className={`fixed left-[0] top-[0] z-[9991] w-full`}
        onClose={() => setIsOpenPopupLocation(false)}
      >
        <div className='text-center'>
          <div
            className='fixed inset-0 bg-dark-900 opacity-60'
            aria-hidden='true'
            onClick={() => setIsOpenPopupLocation(false)}
          />
          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className='inline-block h-screen align-middle'
            aria-hidden='true'
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0 scale-95'
            enterTo='opacity-100 scale-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100 scale-100'
            leaveTo='opacity-0 scale-95'
          >
            <div
              className={`relative z-40 inline-block transform overflow-hidden rounded-lg bg-white text-left align-middle shadow-xl transition-all`}
            >
              <div className='relative mx-auto w-full  max-w-[440px] p-3'>
                <div
                  className='absolute right-1 top-1 flex h-5 w-5 cursor-pointer items-center justify-center'
                  onClick={() => {
                    setIsOpenPopupLocation(false)
                    localStorage?.setItem('saleRegion', 'true')
                  }}
                >
                  <svg
                    width='24'
                    height='24'
                    viewBox='0 0 24 24'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M20.4848 3.51429C18.8066 1.83618 16.6684 0.693416 14.3406 0.230492C12.0129 -0.232431 9.60016 0.00528063 7.40752 0.913568C5.21487 1.82186 3.3408 3.35993 2.02228 5.33329C0.703757 7.30665 0 9.62668 0 12C0 14.3733 0.703757 16.6934 2.02228 18.6667C3.3408 20.6401 5.21487 22.1782 7.40752 23.0864C9.60016 23.9947 12.0129 24.2324 14.3406 23.7695C16.6684 23.3066 18.8066 22.1638 20.4848 20.4857C21.5993 19.3714 22.4833 18.0485 23.0864 16.5925C23.6896 15.1365 24 13.576 24 12C24 10.424 23.6896 8.86351 23.0864 7.40752C22.4833 5.95154 21.5993 4.62861 20.4848 3.51429ZM16.2848 15.0343C16.3705 15.1145 16.4388 15.2114 16.4854 15.319C16.5321 15.4266 16.5562 15.5427 16.5562 15.66C16.5562 15.7773 16.5321 15.8934 16.4854 16.001C16.4388 16.1086 16.3705 16.2055 16.2848 16.2857C16.2048 16.3652 16.1098 16.428 16.0053 16.4707C15.9009 16.5133 15.7891 16.5349 15.6763 16.5343C15.4514 16.5333 15.2359 16.4441 15.0763 16.2857L11.9991 13.2086L8.96485 16.2857C8.8052 16.4441 8.58971 16.5333 8.36485 16.5343C8.25204 16.5349 8.14022 16.5133 8.03579 16.4707C7.93135 16.428 7.83637 16.3652 7.75628 16.2857C7.59663 16.1251 7.50703 15.9079 7.50703 15.6814C7.50703 15.455 7.59663 15.2377 7.75628 15.0771L10.7906 12L7.75628 8.96572C7.61585 8.80175 7.54248 8.59083 7.55081 8.3751C7.55914 8.15938 7.64857 7.95475 7.80122 7.80209C7.95388 7.64944 8.15851 7.56001 8.37423 7.55168C8.58995 7.54335 8.80088 7.61673 8.96485 7.75715L11.9991 10.7914L15.0334 7.75715C15.1128 7.67498 15.2075 7.60924 15.3123 7.5637C15.4171 7.51815 15.5298 7.49368 15.644 7.49169C15.7582 7.4897 15.8717 7.51023 15.978 7.5521C16.0843 7.59397 16.1812 7.65636 16.2634 7.73572C16.3456 7.81508 16.4113 7.90984 16.4569 8.0146C16.5024 8.11936 16.5269 8.23207 16.5289 8.34628C16.5309 8.4605 16.5103 8.57399 16.4685 8.68027C16.4266 8.78655 16.3642 8.88355 16.2848 8.96572L13.2077 12L16.2848 15.0343Z'
                      fill='#CDCDCD'
                    />
                  </svg>
                </div>
                <div className='pop_main flex w-full flex-wrap justify-center'>
                  <Image
                    src='/assets/icons/map.svg'
                    width={115}
                    height={115}
                    alt='map'
                    className='mb-2'
                  />
                  <p className='mb-3 text-center'>
                    Vui lòng chọn khu vực để biết chính xác thông tin
                    <br />
                    <strong>Giá, Khuyến mãi, Tồn kho.</strong>
                  </p>
                  <div
                    className={`list scrollBarCS max-h-60 w-full overflow-y-auto ${
                      regions?.length >= 6 ? 'mb-2 pr-1' : ''
                    }`}
                  >
                    {regions &&
                      regions?.map((item, index) => (
                        <button
                          key={index}
                          className={`relative mb-2 h-12 w-full overflow-hidden rounded border text-center text-base outline-0 hover:border-red ${
                            choose === item?.code ? 'border-red' : ''
                          }`}
                          onClick={() => {
                            setChoose(item?.code)
                          }}
                        >
                          <div className='flex items-center justify-center'>
                            {choose === item?.code ? (
                              <Image
                                src={'/assets/icons/checked-1.svg'}
                                width={27}
                                height={24}
                                alt='checked'
                                className='absolute right-[-1px] top-[-1px]'
                              />
                            ) : (
                              ''
                            )}

                            {item.name}
                          </div>
                        </button>
                      ))}
                  </div>
                  <button
                    className={`relative mt-2 h-12 w-full rounded bg-red text-center text-base font-semibold text-white`}
                    onClick={async () => {
                      setRefetch(true)
                      setIsOpenPopupLocation(false)
                      if (saleRegion !== choose) {
                        await localStorage?.removeItem('province')
                      }
                      await setSaleRegion(choose)
                      await localStorage?.setItem('saleRegion', 'true')
                      setRefetch(false)
                      if (window.location.search.includes('shippingAddress')) {
                        const url = new URL(window.location.href)
                        url.searchParams.delete('shippingAddress')
                        url.searchParams.delete('pis')
                        window.history.pushState({}, '', url.toString())
                      }
                      window.location.reload()
                    }}
                  >
                    XÁC NHẬN
                  </button>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  )
}

export default ModalChooseLocation
