import { useNotification } from '@/containers'
import { getCookie } from 'cookies-next'
import { NextSeo } from 'next-seo'
import Head from 'next/head'
interface MobileAlternate {
  media: string
  href: string
}
type IMetaProps = {
  title: string
  description: string
  keywords?: string[]
  canonical?: string
  mobileAlternate?: MobileAlternate
  imageUrl?: string
  imageAlt?: string
  noIndex?: boolean
  noFollow?: boolean
}

const Meta = ({
  title = '',
  description = '',
  keywords = [],
  canonical = '',
  mobileAlternate,
  imageUrl = '',
  imageAlt = '',
  noIndex = true,
  noFollow = true,
}: IMetaProps) => {
  const { systemConfig } = useNotification()
  function removeAllQueryParams(url: string) {
    // Use URL class to parse the URL and get the base URL without query parameters
    const parsedUrl = new URL(url)
    const baseUrl = parsedUrl.origin + parsedUrl.pathname
    return baseUrl
  }
  const currentRegion = getCookie('sale-region')

  const pixelByRegion = () => {
    switch (currentRegion) {
      case 'MB1':
        return process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID_MB || 671704061518900
      case 'MN1':
        return process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID_MN || 498920596301042

      default:
        return process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID_MN || 498920596301042
    }
  }

  return (
    <>
      <Head>
        <meta charSet='UTF-8' key='charset' />
        <meta
          name='viewport'
          content='width=device-width,initial-scale=1'
          key='viewport'
        />
        <link
          rel='apple-touch-icon'
          href={systemConfig?.companyLogoFavicon}
          key='apple'
        />
        <link
          rel='icon'
          type='image/png'
          sizes='16x16'
          href={systemConfig?.companyLogoFavicon}
          key='icon32'
        />
        <link
          rel='icon'
          href={systemConfig?.companyLogoFavicon}
          key='favicon'
        />
        <script
          id='tiktok-ads'
          dangerouslySetInnerHTML={{
            __html: `!function (w, d, t) {
              w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++
        )ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var i="https://analytics.tiktok.com/i18n/pixel/events.js";ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=i,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};n=document.createElement("script");n.type="text/javascript",n.async=!0,n.src=i+"?sdkid="+e+"&lib="+t;e=document.getElementsByTagName("script")[0];e.parentNode.insertBefore(n,e)};
            
              ttq.load('${process.env.NEXT_PUBLIC_TIKTOK_ID}');
              ttq.page();
            }(window, document, 'ttq');`,
          }}
        />
        <script
          id='fb-pixel'
          dangerouslySetInnerHTML={{
            __html: `!function(f,b,e,v,n,t,s)
      {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
      n.callMethod.apply(n,arguments):n.queue.push(arguments)};
      if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
      n.queue=[];t=b.createElement(e);t.async=!0;
      t.src=v;s=b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t,s)}(window, document,'script',
      'https://connect.facebook.net/en_US/fbevents.js');
      fbq('init', ${currentRegion ? pixelByRegion() : ''});`,
          }}
        />
        <noscript
          dangerouslySetInnerHTML={{
            __html: `<img height="1" width="1" style="display:none"
      src="https://www.facebook.com/tr?id=${
        currentRegion ? pixelByRegion() : ''
      }&ev=PageView&noscript=1" />`,
          }}
        />
      </Head>
      <NextSeo
        title={title}
        description={description}
        canonical={removeAllQueryParams(
          canonical || process.env.NEXT_PUBLIC_URL_WEBSITE || ''
        )}
        noindex={noIndex}
        nofollow={noFollow}
        mobileAlternate={{
          media: 'only screen and (max-width:991px))',
          href:
            mobileAlternate?.href ||
            process.env.NEXT_PUBLIC_URL_WEBSITE_MOBILE ||
            '',
        }}
        additionalMetaTags={[
          {
            name: 'keywords',
            content: keywords
              ? keywords.join(',')
              : `${systemConfig?.websiteBrandName}, chuyên gia điện máy, điện tử, điện lạnh, điều hòa, tủ lạnh, máy giặt, đồ gia dụng, tivi, khuyến mại, giao hàng nhanh`,
          },
        ]}
        openGraph={{
          title: title,
          description: description,
          url: canonical,
          images: [
            {
              url: imageUrl,
              width: 800,
              height: 600,
              alt: imageAlt,
            },
          ],
          site_name: process.env.NEXT_PUBLIC_URL_WEBSITE,
        }}
      />
    </>
  )
}

export { Meta }
